import React from "react";

const CoverTemplate4 = (props) => {
  const { logo, report_title, system, report_period, period1, period2, report_date, report_name, width, height, Primary, Secondary, logo_s, logoX, logoY } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 793.70081 1122.5197" version="1.1" id="svg14348" xmlSpace="preserve" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
	<defs id="defs14345">
		<linearGradient id="swatch6">
			<stop style={{ stopColor:"#3edb00", stopOpacity:"1" }} offset="0" id="stop7"/>
		</linearGradient>
	</defs>
	<g id="layer1">
		<rect style={{ display:"inline", fill:"#ffffff", fillOpacity:"0.25", stroke:"none", strokeWidth:"0", strokeLineCap:"round", strokeLineJoin:"round", strokeDashArray:"none" }} id="logo_holder_client" width="793.46741" height="123" x="0" y="0" ry="0"/>
		<image width="102.33347" height="102.33347" preserveAspectRatio="none" style={{ imageRendering:"optimizeQuality" }} xlinkHref={logo} id="client_logo" x="25" y="12.159909"/>
		<image width="102.33347" height="102.33347" preserveAspectRatio="none" style={{ imageRendering:"optimizeQuality" }} xlinkHref={logo_s} id="app_logo" x="664.23615" y="1011.6999"/>
		<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"41.3989px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", whiteSpace:"pre", inlineSize:"333.985", display:"inline", fill:"#ffffff", fillOpacity:"1", stroke:"#042c62", strokeWidth:"0.970285", strokeOpacity:"1" }} x="531.33954" y="281.7254" id="report_title_top" transform="translate(-461.50315,-19.956601)">
			<tspan x="531.33954" y="281.7254" id="tspan5">
				<tspan style={{ textAlign:"center",textAnchor:"middle" }} id="tspan4">Period Vs Period</tspan>
			</tspan>
			<tspan x="531.33954" y="333.47401" id="tspan7">
				<tspan style={{ textAlign:"center",textAnchor:"middle" }} id="tspan6">Analysis</tspan>
			</tspan>
		</text>
		<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"43.6625px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#000000", strokeWidth:"1.02333", strokeOpacity:"1" }} x="22.313755" y="522.30341" id="system_name">
			<tspan id="tspan4577" x="22.313755" y="522.30341">{system}</tspan>
		</text>
		<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"43.6625px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", stroke:"#000000", strokeWidth:"1.02333", strokeOpacity:"1" }} x="22.313755" y="627.99475" id="report_title">
			<tspan id="tspan2589" x="22.313755" y="627.99475">Period Vs Period Analysis</tspan>
		</text>
		<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"30.0178px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#030000", strokeWidth:"1.02333", strokeOpacity:"1" }} x="23.313114" y="697.55774" id="report_period">
			<tspan id="tspan4581" x="23.313114" y="697.55774">Period Analyzed</tspan>
		</text>				
		<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"27.2889px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", textAlign:"start",writingMode:"lr-tb", direction:"ltr", textAnchor:"start", fill:"#1a1a1a", fillOpacity:"0.797267", stroke:"#1a1a1a", strokeWidth:"1.02333", strokeLineCap:"round", strokeLineJoin:"round" }} x="23.512966" y="749.5105" id="period1">
			<tspan id="tspan1" x="23.512966" y="749.5105">{period1}</tspan>
		</text>
		<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"27.2889px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", textAlign:"start", writingMode:"lr-tb", direction:"ltr", textAnchor:"start", fill:"#1a1a1a", fillOpacity:"0.797267", stroke:"#1a1a1a", strokeWidth:"1.02333", strokeLineCap:"round", strokeLineJoin:"round" }} x="23.512966" y="803.47522" id="period2">
			<tspan id="tspan2" x="23.512966" y="803.47522">{period2}</tspan>
		</text>
		<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"32.7469px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", fillOpacity:"1",stroke:"#000000", strokeWidth:"1.02333", strokeOpacity:"0" }} x="23.113277" y="936.88269" id="report_name">
			<tspan id="tspan6919" x="23.113277" y="936.88269">{report_title}</tspan>
		</text>
		<text xmlSpace="preserve" style={{ fontStyle:"normal",fontVariant:"normal",fontWeight:"normal",fontStretch:"normal", fontSize:"32.7469px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#030000", strokeWidth:"1.02333", strokeOpacity:"1" }} x="22.95335" y="997.91193" id="report_date">
			<tspan id="tspan6915" x="22.95335" y="997.91193">Report Date : {report_date}</tspan>
		</text>		
		<text xmlSpace="preserve" style={{ fontStyle:"normal",fontVariant:"normal",fontWeight:"normal",fontStretch:"normal", fontSize:"21.831px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", display:"inline", fill:"#03527c", fillOpacity:"1", stroke:"#03527c",strokeWidth:"1.02333", strokeOpacity:"1" }} x="25.958632" y="1106.3077" id="copyright">
			<tspan id="tspan6935" x="25.958632" y="1106.3077">© 2025 PerfScan. All rights reserved.</tspan>
		</text>
		<path style={{ fill:Secondary, fillOpacity:"1", strokeWidth:"2.75727", strokeLineCap:"round", strokeLineJoin:"round", strokeOpacity:"0.995781" }} d="M 1.867019,123.45424 793.96688,123.1509 793.80612,267.97769 470.98071,454.68882 0.23988103,453.4601 Z" id="color_secondary"/>
		<path style={{ fill:Primary, fillOpacity:"1", strokeWidth:"2.91086", strokeLineCap:"round", strokeLineJoin:"round", strokeOpacity:"0.995781" }} d="m 480.93809,454.63787 312.87054,-178.44999 0.0227,178.41082 z" id="color_primary_2"/>
		<rect style={{ fill:Primary, fillOpacity:"1", strokeWidth:"3.0016", strokeLineCap:"round", strokeLineJoin:"round", strokeOpacity:"0.995781" }} id="color_primary" width="471.03464" height="454.6937" x="0" y="0" rx="0" ry="0"/>		
	</g>
</svg>)
}

export default CoverTemplate4;