import React from "react";

const CoverTemplate1 = (props) => {
    const { logo, report_name, system, report_period, report_date, width, height, Primary, Secondary, logo_s } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 816 1056" version="1.1" id="svg5" xmlns="http://www.w3.org/2000/svg">
        <defs id="defs2"/>
        <g id="layer1">
            <rect style={{ fill:"#002d89", fillOpacity:"1", stroke:"#d6d6d6", strokeWidth:"0.87513", strokeOpacity:"1" }} id="color_Secondary" width="691.07764" height="827.85339" x="-381.40567" y="125.60638" rx="23.305882" transform="rotate(-37.009861)"/>
            <rect style={{ fill:"#31b900", fillOpacity:"1", stroke:"none", strokeWidth:"0.87513", strokeOpacity:"1" }} id="colorPrimary" width="691.07764" height="827.85339" x="-492.7814" y="90.579781" rx="23.305882" transform="rotate(-37.009861)"/>
            <rect style={{ fill:"#ffffff", fillOpacity:"1",stroke:"#ffffff", strokeWidth:"0.929495", strokeOpacity:"1" }} id="rect9901" width="734.0094" height="879.28204" x="-456.56516" y="-0.45963579" rx="24.75371" transform="rotate(-37.009861)"/>
            <image width="229.6983" height="45" preserveAspectRatio="none" style={{ imageRendering:"optimizeQuality" }} href={logo} id="app_logo" x="580.81451" y="1009.6268"/>
            <text space="preserve" style={{fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"21.3333px", fontFamily:'Trebuchet MS',  fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#030000", strokeOpacity:"1" }} x="4.1129503" y="1037.7333" id="copyright">
                <tspan id="tspan6935" x="4.1129503" y="1037.7333">
                    © 2025 PerfScan. All rights reserved.
                </tspan>
            </text>
            <text space="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"32px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal",fill:"#1a1a1a", fillOpacity:"1",stroke:"#000000", strokeOpacity:"0" }} x="11.620791" y="757.8111" id="client_name">
                <tspan id="tspan6919" x="11.620791" y="757.8111">
                    Doug's Golf Supply
                </tspan>
            </text>
            <text space="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal",fontStretch:"normal", fontSize:"32px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#030000", strokeOpacity:"1" }} x="11.464541" y="715.65338" id="report_date">
                <tspan id="tspan6915" x="11.464541" y="715.65338" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"32px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fill:"#1a1a1a", stroke:"#030000", strokeOpacity:"1" }}>
                    Report Date : 02/13/2023
                </tspan>
            </text>            
            <text space="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"bold", fontStretch:"normal", fontSize:"21.3333px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", textAlign:"center", textAnchor:"middle", fill:"#042c62", fillOpacity:"1", strokeWidth:"3.77953", strokeLinecap:"round", strokeLinejoin:"round" }} x="332.51614" y="645.62677" id="num_cores_growth">
                <tspan id="tspan1" x="332.51614" y="645.62677">
                    Num Cores - Growth
                </tspan>
            </text>            
            <text space="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"bold", fontStretch:"normal", fontSize:"21.3333px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal",textAlign:"center",textAnchor:"middle", fill:"#042c62", fillOpacity:"1", strokeWidth:"3.77953", strokeLinecap:"round", strokeLinejoin:"round" }} x="218.03836" y="586.17511" id="proposed_system">
                <tspan id="tspan2-5" x="218.03836" y="586.17511" style={{ textAlign:"start",textAnchor:"start" }} >
                    - S1022 9105-22B-78733F1
                </tspan>
                <tspan x="218.03836" y="612.84174" id="tspan5" style={{ textAlign:"start",textAnchor:"start" }}>
                    - EPGR-1 Cores(26575)
                </tspan>
            </text>
           
            <text space="preserve" style={{ fontStyle: "normal",fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"24px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#030000", strokeOpacity:"1" }} x="14.91271" y="586.948" id="proposed_system_label">
                <tspan id="tspan4581-1" x="14.91271" y="586.948" style={{ fontStyle: "normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"24px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal" }}>
                    Proposed System 
                </tspan>
            </text>
            <text space="preserve" style={{ fontStyle: "normal", fontVariant:"normal", fontWeight:"bold", fontStretch:"normal", fontSize:"21.3333px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", textAlign:"center", textAnchor:"middle", fill:"#042c62", fillOpacity:"1", strokeWidth:"3.77953", strokeLinecap:"round", strokeLinejoin:"round" }} x="223.369" y="523.18726" id="current_system">
                <tspan id="tspan2-1" x="223.369" y="523.18726" style={{ fontStyle: "normal", fontVariant:"normal", fontWeight:"bold", fontStretch:"normal", fontSize:"21.3333px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", textAlign:"start", textAnchor:"start" }} >
                    - S1022 9105-22B-78733F1
                </tspan>
                <tspan x="223.369" y="549.85388" id="tspan4" style={{ fontStyle: "normal", fontVariant:"normal", fontWeight:"bold", fontStretch:"normal",fontSize:"21.3333px",fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal",textAlign:"start", textAnchor:"start" }} >
                    - EPGR-1 Cores(26575)
                </tspan>
            </text>
            
            <text space="preserve" style={{ fontStyle: "normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"24px",fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fill:"#1a1a1a", fillOpacity:"1",stroke:"#030000", strokeOpacity:"1" }} x="18.058872" y="524.68616" id="current_system_label">
                <tspan id="tspan4581-5" x="18.058872" y="524.68616" style={{ fontStyle: "normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"24px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEasAsian:"normal" }} >
                    Current System 
                </tspan>
            </text>             
            <text space="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"bold", fontStretch:"normal", fontSize:"21.3333px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", textAlign:"center", textAnchor:"middle", fill:"#042c62", fillOpacity:"1", strokeWidth:"3.77953", strokeLinecap:"round", strokeLinejoin:"round" }} x="317.52393" y="475.37952" id="busy_day">
                <tspan id="tspan2" x="317.52393" y="475.37952">
                    - Busy Day Selected
                </tspan>
            </text>            
            <text space="preserve" style={{ fontStyle: "normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"24px",fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#030000", strokeOpacity:"1" }} x="17.276098" y="474.87952" id="busy_day_label">
                <tspan id="tspan4581" x="17.276098" y="474.87952" style={{ fontStyle: "normal", FontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"24px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEasAsian:"normal" }}>
                    Busy Day Selected 
                </tspan>
            </text>            
            <text space="preserve" style={{ fontStyle: "normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"37.3333px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fill:"#1a1a1a", stroke:"#000000", strokeOpacity:"1" }} x="15.075986" y="416.89383" id="report_name">
                <tspan id="tspan2589" x="15.075986" y="416.89383" style={{ fontStyle: "normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"37.3333px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fill:"#1a1a1a" }} >
                    Capacity Planning Analysis Report
                </tspan>
            </text>            
            <text space="preserve" style={{ fontStyle: "normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"42.6667px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#000000",strokeOpacity:"1" }} x="14.395328" y="246.77705" id="system_name">
                <tspan id="tspan4577" x="14.395328" y="246.77705">
                    Americas - Harrisburg
                </tspan>
            </text>           
            <rect style={{ fill:"#f4f4f4", fillOpacity:"0.874194", stroke:"none", strokeWidth:"1.01101", strokeLinecap:"round", strokeLinejoin:"round", strokeOpacity:"1" }} id="logo_holder" width="816.68604" height="98.988983" x="0" y="30"/>
            <image width="245" height="50" preserveAspectRatio="none" style={{imageRendering:"optimizeQuality"}} href={logo_s} id="image1" x="27.843018" y="54.494492"/>           
        </g>
    </svg>
    )
}

export default CoverTemplate1;
