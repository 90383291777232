import React from "react";

const CoverTemplate3 = (props) => {
	const { logo, report_title, system, report_period, period1, period2, report_date, report_name, width, height, Primary, Secondary, logo_s, logoX, logoY } = props;

	return (
		<svg width={width} height={height} viewBox="0 0 793.70081 1122.5197" version="1.1" id="svg14348" xmlSpace="preserve" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
			<defs id="defs14345">
				<linearGradient id="swatch6">
					<stop style={{ stopColor:Secondary, stopOpacity:"1" }} offset="0" id="stop7" />
				</linearGradient>
				<linearGradient xlinkHref="#swatch6" id="linearGradient7" x1="-0.32677025" y1="133.49591" x2="41.871536" y2="133.49591" gradientUnits="userSpaceOnUse" gradientTransform="matrix(3.6684649,0,0,4.0152918,1.1987311,0.54686031)" />
			</defs>
			<g id="layer1">
				
				<rect style={{ display:"inline", fill:"#ffffff", fillOpacity:"0.25", stroke:"none", strokeWidth:"0", strokeLineCap:"round", strokeDashArray:"none" }} id="logo_holder_client" width="793.21979" height="125.87018" x="0" y="37.026295" ry="0" />
				
				<rect style={{ fill:"#ffffff",fillOpacity:"1",stroke:"#31b900", strokeWidth:"0", strokeLineCap:"round", strokeLineJoin:"round" }} id="rect27464" width="791.34955" height="47.45702" x="1.5107033" y="977.16125" />
				<rect style={{ display:"inline", fill:Primary, fillOpacity:"1", stroke:"none", strokeWidth:"1.12505", strokeOpacity:"1" }} id="colorPrimary" width="638.8949" height="290.3031" x="154.40794" y="0" />
				<rect style={{ opacity:"1", fill:Secondary, fillOpacity:"1", stroke:"none", strokeWidth:"3.47952", strokeLineCap:"round", strokeLineJoin:"round", strokeOpacity:"0.995781" }} id="rect1" width="154.80302" height="1073.1438" x="0" y="0" rx="0" ry="0" />
				<image width="102.33347" height="102.33347" preserveAspectRatio="none" style={{ imageRendering:"optimizeQuality" }} xlinkHref={logo_s} id="app_logo" x="662.66992" y="1012" />
				<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"43.6625px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#000000", strokeWidth:"1.02333", strokeOpacity:"1" }} x="162.31377" y="418.70422" id="system_name">
					<tspan id="tspan4577" x="162.31377" y="418.70422">{system}</tspan>
				</text>
				<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"43.6625px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", stroke:"#000000", strokeWidth:"1.02333", strokeOpacity:"1" }} x="162.31377" y="524.39557" id="report_title">
					<tspan id="tspan2589" x="162.31377" y="524.39557">Period Vs Period Analysis</tspan>
				</text>
				<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"30.0178px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#030000", strokeWidth:"1.02333", strokeOpacity:"1" }}  x="163.31313" y="593.95856" id="report_period">
					<tspan id="tspan4581" x="163.31313" y="593.95856">{report_period}</tspan>
				</text>
				<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"27.2889px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", textAlign:"start", writingMode:"lr-tb", direction:"ltr", textAnchor:"start", fill:"#1a1a1a", fillOpacity:"0.797267", stroke:"#1a1a1a", strokeWidth:"1.02333", strokeLineCap:"round", strokeLineJoin:"round" }} x="163.51297" y="645.91132" id="period1">
					<tspan id="tspan1" x="163.51297" y="645.91132">{period1}</tspan>
				</text>
				<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"27.2889px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", textAlign:"start", writingMode:"lr-tb", direction:"ltr", textAnchor:"start", fill:"#1a1a1a", fillOpacity:"0.797267", stroke:"#1a1a1a", strokeWidth:"1.02333", strokeLineCap:"round", strokeLineJoin:"round" }} x="163.51297" y="699.87604" id="period2">
					<tspan id="tspan2" x="163.51297" y="699.87604">{period2}</tspan>
				</text>
				<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"32.7469px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", fillOpacity:"1", stroke:"#000000", strokeWidth:"1.02333", strokeOpacity:"0" }} x="163.11328" y="833.28351" id="report_name">
					<tspan id="tspan6919" x="163.11328" y="833.28351">{report_title}</tspan>
				</text>
				<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"32.7469px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", fill:"#1a1a1a", fillOpacity:"1",stroke:"#030000", strokeWidth:"1.02333", strokeOpacity:"1" }} x="162.95335" y="894.31274" id="report_date">
					<tspan id="tspan6915" x="162.95335" y="894.31274">Report Date : {report_date}</tspan>
				</text>
				<text xmlSpace="preserve" style={{ fontStyle:"normal", fontVariant:"normal", fontWeight:"normal", fontStretch:"normal", fontSize:"21.831px", fontFamily:'Trebuchet MS', fontVariantLigatures:"normal", fontVariantCaps:"normal", fontVariantNumeric:"normal", fontVariantEastAsian:"normal", display:"inline", fill:"#03527c", fillOpacity:"1", stroke:"#03527c", strokeWidth:"1.02333", strokeOpacity:"1" }} x="24.392401" y="1106.6078" id="copyright">
					<tspan id="tspan6935" x="24.392401" y="1106.6078">© 2025 PerfScan. All rights reserved.</tspan>
				</text>
			</g>
		</svg>)
}

export default CoverTemplate3;
